.ag-theme-quartz {
    --ag-header-height: 70px;
    --ag-header-foreground-color: black;
    --ag-header-background-color: rgb(209, 255, 240);
    --ag-font-family: 'Quicksand', sans-serif;
    --ag-font-weight:500;
  }
  
  
  .ag-header-cell-text {
    font-weight: 500;
  }

  /* .ag-theme-quartz {
    font-family: "Your Font Name", sans-serif;
  } */
  
  
  .row-white {
    background-color: rgb(255, 255, 255);
  }
  
  .row-gray {
    background-color: rgb(236, 236, 236);
  }
  
  .ag-theme-quartz .ag-row,
  .ag-theme-quartz .ag-cell {
    border: none;
    outline: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .ag-theme-alpine .ag-header {
    border-bottom: none;
  }
  
  .ag-theme-quartz .ag-row,
.ag-theme-quartz .ag-cell {
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: normal; /* Allow wrapping of long content */
  word-wrap: break-word;
}


.ag-theme-quartz .ag-cell {
  height: fit-content !important; /* Ensure the cell height adjusts to content */
} 
  
  .ag-theme-quartz .ag-row:hover,
  .ag-theme-quartz .ag-cell:hover {
    background-color: transparent !important;
  }
  
  .ag-theme-quartz .ag-root-wrapper {
    border-radius: 0 !important;
  }
  
  .ag-theme-quartz .ag-root {
    border-radius: 0 !important;
  }
  
  .ag-cell:focus {
    outline: none !important;
    border: none !important;
    
  }
  